import { ArrowRight } from "@phosphor-icons/react"
import { Row } from "@tokenterminal/ui/Box"
import { LinkButtonV4 } from "@tokenterminal/ui/Button/LinkButton.v4"
import { Card } from "@tokenterminal/ui/Card/Card"
import { Heading } from "@tokenterminal/ui/Heading"
import { Icon } from "@tokenterminal/ui/Icon/Icon"
import { Stack } from "@tokenterminal/ui/Stack"
import { Text } from "@tokenterminal/ui/Text"
import Image from "next/image"
import Container from "../common/Container"
import { groupedTestimonials } from "./content"
import {
  desktopRowCss,
  headingCss,
  mobileRowCss,
  testimonialCardCss,
} from "./Testimonials.css"
import { BASE_PATH } from "@/constants/app.mjs"

const TestimonialCard = ({ testimonial }: { testimonial: any }) => (
  <Card as={Stack} className={testimonialCardCss}>
    <Row gap="4x">
      <Image
        alt={testimonial.name + " avatar"}
        src={testimonial.avatar}
        width={32}
        height={32}
        style={{
          borderRadius: "50%",
          objectFit: "cover",
          objectPosition: "top left",
        }}
      />
      <Stack gap="0">
        <Text size="14" fontWeight="500">
          {testimonial.name}
        </Text>
        <Text size="14" style={{ color: "#aaa" }}>
          {testimonial.company}
        </Text>
      </Stack>
      {false && (
        <Image
          alt={testimonial.logo_url}
          src={`${BASE_PATH}/static/images/marketing/${testimonial.logo_url}`}
          width={100}
          height={100}
          style={{
            width: "40%",
            height: "auto",
            maxHeight: "40px",
            objectFit: "contain",
            objectPosition: "left",
          }}
        />
      )}
    </Row>
    <Text
      size={testimonial.quote.length > 400 ? "14" : "14"}
      lineHeight={testimonial.quote.length > 400 ? "16" : "16"}
      style={{ color: "#aaa" }}
    >
      &quot;{testimonial.quote}&quot;
    </Text>
  </Card>
)

const Testimonials = () => {
  return (
    <Container>
      <Stack alignItems="center" style={{ padding: "100px 0" }}>
        <Heading className={headingCss} level={1}>
          Hear from our customers
        </Heading>
        {/* Desktop */}
        <Row gap="8x" className={desktopRowCss}>
          {groupedTestimonials.map((group, index) => (
            <Stack
              gap="8x"
              key={index}
              style={{ flex: 1, marginTop: index !== 1 ? "50px" : 0 }}
            >
              {group.map((testimonial, index) => (
                <TestimonialCard key={index} testimonial={testimonial} />
              ))}
            </Stack>
          ))}
        </Row>
        {/* Mobile */}
        <Row className={mobileRowCss}>
          {groupedTestimonials.flat().map((testimonial, index) => (
            <TestimonialCard key={index} testimonial={testimonial} />
          ))}
        </Row>
        <LinkButtonV4 size="large" variant="ghost" href="/customers">
          Explore more customer stories
          <Icon as={ArrowRight} />
        </LinkButtonV4>
      </Stack>
    </Container>
  )
}

export default Testimonials
