import { Box } from "@tokenterminal/ui/Box"
import { fadeInUpQuickCss } from "components/marketing/common/common.css"
import { useCallback, useLayoutEffect, useRef, useState } from "react"
import { slides } from "../content"
import HeroHeading from "./HeroHeading"

type Props = {
  currentSlideIndex: number
}

const GAP = 10

const RotatingText = ({ currentSlideIndex }: Props) => {
  const windowRef = useRef<HTMLDivElement>(null)
  const textRefs = useRef<HTMLHeadingElement[]>([])
  const [textWidths, setTextWidths] = useState<number[]>([])

  const calculateWidths = useCallback(() => {
    if (textRefs.current.length === 0) return

    const widths = textRefs.current.map((ref) => {
      return ref.getBoundingClientRect().width
    })
    setTextWidths(widths)
  }, [])

  useLayoutEffect(() => {
    calculateWidths()
    window.addEventListener("resize", calculateWidths)
    return () => {
      window.removeEventListener("resize", calculateWidths)
    }
  }, [calculateWidths])

  return (
    <Box
      ref={windowRef}
      className={fadeInUpQuickCss}
      style={{
        flexShrink: 0,
        height: "calc(100% + 40px)",
        width: textWidths[currentSlideIndex] + "px",
        transition: "width 0.25s 0.1s cubic-bezier(0.19, 1, 0.22, 1)",
        position: "relative",
        overflow: "hidden",
        animationDelay: "0.25s",
      }}
    >
      <Box
        style={{
          position: "absolute",
          right: 0,
          top: 15 + currentSlideIndex * -(80 + GAP) + "px",
          transition: "top 0.5s",
        }}
      >
        {slides.map((slide, index) => (
          <Box
            key={slide.word}
            style={{
              height: "80px",
              marginBottom: GAP + "px",
              opacity: currentSlideIndex === index ? 1 : 0,
              filter: currentSlideIndex === index ? "blur(0px)" : "blur(15px)",
              transition: "opacity 0.15s .1s, filter 300ms .1s",
              willChange: "opacity, filter",
            }}
          >
            <HeroHeading
              ref={(el) => {
                textRefs.current[index] = el as HTMLHeadingElement
              }}
              animationIndex={3}
              style={{
                display: "inline",
                whiteSpace: "nowrap",
              }}
            >
              {slide.word}
            </HeroHeading>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default RotatingText
