import { Calendar, Envelope, TelegramLogo } from "@phosphor-icons/react"
import { LinkButtonV4 } from "@tokenterminal/ui/Button/LinkButton.v4"
import { Card } from "@tokenterminal/ui/Card/Card"
import { Heading } from "@tokenterminal/ui/Heading"
import { Icon } from "@tokenterminal/ui/Icon/Icon"
import { Stack } from "@tokenterminal/ui/Stack"
import Container from "../common/Container"
import { cardCss, containerCss, headingCss } from "./ContactUs.css"

const emailAddress = "people@tokenterminal.xyz"
const subject = "Contact Token Terminal"
const mailToLink = `mailto:${emailAddress}?subject=${subject}`

const ContactUs = () => {
  return (
    <Container as={Stack} alignItems="center" className={containerCss}>
      <Card
        borderRadius="6x"
        className={cardCss}
        style={{
          maxWidth: "100%",
        }}
      >
        <Stack justifyContent="center" alignItems="center" gap="8x">
          <Heading level={1} className={headingCss}>
            Talk to our team
          </Heading>
          <Stack gap="4x" style={{ width: "300px" }}>
            <LinkButtonV4
              size="large"
              width="100%"
              target="_blank"
              href="https://t.me/TokenTerminalHQ"
            >
              <Icon as={TelegramLogo} />
              Chat on Telegram
            </LinkButtonV4>
            <LinkButtonV4
              size="large"
              width="100%"
              target="_blank"
              href="https://calendly.com/token-terminal/intro-call"
            >
              <Icon as={Calendar} />
              Book a demo
            </LinkButtonV4>
            <LinkButtonV4
              variant="outline"
              size="large"
              width="100%"
              target="_blank"
              href={mailToLink}
            >
              <Icon as={Envelope} />
              Email us
            </LinkButtonV4>
          </Stack>
          {/* Potentially add this back */}
          {/* <Stack justifyContent="center" gap="4x">
            <Input placeholder="Email" />
            <Input placeholder="Telegram handle (optional)" />
            <Input placeholder="How can we help you? (optional)" />
            <ButtonV4 size="large" width="100%">
              Submit
            </ButtonV4>
            <Divider />
            <Text size="14" color="secondary">
              Or send us an email at&nbsp;
              <Link
                href="mailto:people@tokenterminal.xyz"
                display="inline"
                style={{ color: "#7EFFDE" }}
              >
                people@tokenterminal.xyz
              </Link>
            </Text>
          </Stack> */}
        </Stack>
      </Card>
    </Container>
  )
}

export default ContactUs
