import { Box } from "@tokenterminal/ui/Box"
import Container from "../common/Container"
import Layout from "../common/Layout"
import ContactUs from "./ContactUs"
import CustomerGrid from "./CustomerGrid"
import FeatureCallouts from "./FeatureCallouts"
import Hero from "./hero/Hero"
import { solutionsForEveryScaleSectionCss } from "./HomePage.css"
import HomepageChartSection from "./HomepageChartSection"
import SolutionsForEveryScale from "./SolutionsForEveryScale"
import Testimonials from "./Testimonials"
import { featureCallouts } from "@/components/marketing/home/content"
const HomePage = () => {
  return (
    <Layout>
      <Hero />
      <Container>
        <CustomerGrid />
      </Container>
      <Box
        position="relative"
        style={{
          background: "rgba(10, 48, 39, 0.5)",
        }}
      >
        <Box
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            opacity: ".5",
            mixBlendMode: "multiply",
          }}
        />
        <Box
          style={{
            background:
              "linear-gradient(180deg, #0d0d0d, transparent, #0d0d0d)",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
        />
        <Container>
          <HomepageChartSection />
        </Container>
      </Box>
      <Box>
        <Container>
          <FeatureCallouts features={featureCallouts} />
        </Container>
      </Box>
      <Box className={solutionsForEveryScaleSectionCss}>
        <Container>
          <SolutionsForEveryScale />
        </Container>
      </Box>
      <Testimonials />
      {/* This was the old anchor link id for the contact section. Changed it to the more generic "contact" but kept this here for "backwards compatibility" of old links. */}
      <div id="landing-sales-form" />
      <Box
        id="contact"
        style={{
          background:
            "linear-gradient(180deg, rgba(13, 13, 13, 1) 45%, rgba(13, 13, 13, 0.5) 100%), linear-gradient(90deg, #0a1b1d, #003426)",
        }}
      >
        <ContactUs />
      </Box>
    </Layout>
  )
}

export default HomePage
