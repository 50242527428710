import { Heading, type HeadingProps } from "@tokenterminal/ui/Heading"
import { forwardRef } from "react"
import { heroHeadingCss } from "./HeroHeading.css"

const HeroHeading = forwardRef<
  HTMLHeadingElement,
  HeadingProps<"h1"> & {
    animationIndex: number
  }
>(({ animationIndex, className, style, ...props }, ref) => {
  return (
    <Heading
      className={[heroHeadingCss, className]}
      ref={ref}
      style={{
        animationDelay: `${animationIndex * 100}ms`,
        ...style,
      }}
      {...props}
    />
  )
})

HeroHeading.displayName = "HeroHeading"

export default HeroHeading
