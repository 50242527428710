import { Box } from "@tokenterminal/ui/Box"
import { slides } from "../content"
import {
  horizontallyMovingGradientLineCss,
  horizontallyDottedLineCss,
} from "./HorizontalDottedLine.css"

type Props = {
  animationDelayIndex: number
  currentSlideIndex: number
  positionStyle?: React.CSSProperties
}

const HorizontalDottedLine = ({
  animationDelayIndex,
  currentSlideIndex,
  positionStyle,
}: Props) => {
  return (
    <Box
      className={horizontallyDottedLineCss}
      style={{
        height: "1px",
        borderTop: "1px dashed",
        borderColor: "rgba(197, 200, 224, 0.18)",
        zIndex: -1,
        position: "relative",
        ...positionStyle,
      }}
    >
      <Box
        style={{
          position: "absolute",
          left: 0,
          top: "-1px",
          right: 0,
          bottom: 0,
          height: "2px",
          background:
            "linear-gradient(to right, #0d0d0d, transparent, transparent, transparent, #0d0d0d)",
        }}
      />
      <Box
        className={horizontallyMovingGradientLineCss}
        style={{
          position: "absolute",
          left: 0,
          top: "-1px",
          right: 0,
          bottom: 0,
          height: "1px",
          width: "25%",
          background: `linear-gradient(to right, transparent, ${slides[currentSlideIndex].accentColor}, transparent)`,
          boxShadow: `0 0 10px ${slides[currentSlideIndex].accentColor})`,
          animationDelay: `${animationDelayIndex * 10}s`,
        }}
      />
    </Box>
  )
}

export default HorizontalDottedLine
