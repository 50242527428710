import { ChartWithConfigId } from "@tokenterminal/core/feature/chart/ChartWithConfigId"
import { Box } from "@tokenterminal/ui/Box"
import { ButtonV4 } from "@tokenterminal/ui/Button/Button.v4"
import { Card } from "@tokenterminal/ui/Card/Card"
import { Heading } from "@tokenterminal/ui/Heading/Heading"
import { Row } from "@tokenterminal/ui/Row"
import { Stack } from "@tokenterminal/ui/Stack"
import { useState } from "react"
import {
  homepageChartSectionCss,
  chartButtonRowCss,
  chartWrapperCardCss,
  headingCss,
  chartContainerCss,
  gradientCss,
} from "./HomepageChartSection.css"

const charts = [
  {
    title: "Fees",
    chartId: "MGY3Nzc5OTRhMjk3MzViYjllYzEwODEz",
  },
  {
    title: "Daily active users",
    chartId: "YTAzNWEyYmNmNzhiOWZiMzU3MTFmNWEw",
  },
  {
    title: "Trading volume",
    chartId: "ODc3M2QwZDZlMDY3OGRkMWM2ZjJiN2Ew",
  },
  {
    title: "Active loans",
    chartId: "OTA0YzdmMTY2ZGJhMzhkODgxNDE0OWYz",
  },
  {
    title: "Stablecoin transfers",
    chartId: "NGY0Yzk4OTU1YWQ4ZjQ4ZGRmOTYxNWY0",
  },
]

/* TODO: Replace with actual reusable chart component */
const HomepageChartSection = () => {
  const [activeChartIndex, setActiveChartIndex] = useState(0)

  return (
    <Stack className={homepageChartSectionCss} alignItems="center" width="100%">
      <Heading level={1} className={headingCss}>
        Blockchain data you can digest
      </Heading>
      <Box
        position="relative"
        width={["100%", "auto"]}
        display={["block", "none"]}
      >
        <Row gap="1x" className={chartButtonRowCss}>
          {charts.map((chart, index) => (
            <ButtonV4
              variant={activeChartIndex === index ? "muted" : "ghost"}
              onClick={() => setActiveChartIndex(index)}
              style={{
                boxShadow: "none",
                zIndex: activeChartIndex === index ? 2 : 0,
              }}
            >
              {chart.title}
            </ButtonV4>
          ))}
        </Row>
        <Box className={gradientCss} />
      </Box>
      <Box
        position="relative"
        width={["100%", "auto"]}
        display={["none", "block"]}
      >
        <Row gap="1x" className={chartButtonRowCss}>
          {charts.map((chart, index) => (
            <ButtonV4
              variant={activeChartIndex === index ? "muted" : "ghost"}
              onClick={() => setActiveChartIndex(index)}
              size="large"
              style={{
                boxShadow: "none",
                zIndex: activeChartIndex === index ? 2 : 0,
              }}
            >
              {chart.title}
            </ButtonV4>
          ))}
        </Row>
        <Box className={gradientCss} />
      </Box>
      <Box className={chartContainerCss}>
        {charts.map((chart, index) => (
          <Card
            className={chartWrapperCardCss}
            key={chart.chartId}
            data-is-active={activeChartIndex === index}
          >
            <ChartWithConfigId
              id={chart.chartId}
              isVisible
              showDescription={false}
              showTitle={false}
            />
          </Card>
        ))}
      </Box>
    </Stack>
  )
}

export default HomepageChartSection
