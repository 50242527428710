import { Box } from "@tokenterminal/ui/Box"
import { slides } from "../content"
import {
  verticallyDottedLineCss,
  verticallyMovingGradientLineCss,
} from "./VerticalDottedLine.css"

type Props = {
  positionStyle?: React.CSSProperties
  currentSlideIndex: number
  animationDelayIndex: number
}

const VerticalDottedLine = ({
  animationDelayIndex,
  currentSlideIndex,
  positionStyle,
}: Props) => {
  return (
    <Box
      className={verticallyDottedLineCss}
      style={{
        width: "1px",
        borderRight: "1px dashed",
        borderColor: "rgba(197, 200, 224, 0.18)",
        zIndex: -1,
        marginTop: "-500px",
        marginBottom: "-500px",
        position: "relative",
        ...positionStyle,
      }}
    >
      <Box
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          height: "100%",
          width: "2px",
          background:
            "linear-gradient(to bottom, #0d0d0d, transparent, transparent, transparent, #0d0d0d)",
        }}
      />
      <Box
        className={verticallyMovingGradientLineCss}
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          height: "25%",
          width: "1px",
          background: `linear-gradient(to bottom, transparent, ${slides[currentSlideIndex].accentColor}, transparent)`,
          boxShadow: `0 0 10px ${slides[currentSlideIndex].accentColor})`,
          animationDelay: `${animationDelayIndex * 20}s`,
        }}
      />
    </Box>
  )
}

export default VerticalDottedLine
