import {
  ArrowRight,
  CaretLeft,
  CaretRight,
  Pause,
  Play,
} from "@phosphor-icons/react"
import { Box, type BoxProps, Row } from "@tokenterminal/ui/Box"
import { IconButtonV4 } from "@tokenterminal/ui/Button/IconButton.v4"
import { LinkButtonV4 } from "@tokenterminal/ui/Button/LinkButton.v4"
import { Icon } from "@tokenterminal/ui/Icon/Icon"
import { Stack } from "@tokenterminal/ui/Stack"
import { Text } from "@tokenterminal/ui/Text"
import { useCallback, useEffect, useState } from "react"
import { fadeInUpQuickCss } from "../../common/common.css"
import Container from "../../common/Container"
import { heroContainerCss } from "../Hero.css"
import FeaturedContent from "./FeaturedContent"
import {
  GAP,
  heroGridCss,
  responsiveGapCss,
  hideSectionCss,
  desktopCtaCss,
  heroContentSectionCss,
  desktopControlsContainerCss,
  tabletControlsCss,
  mobileCTACss,
  blockchainImagePaneCss,
  dataImagePaneCss,
  youCanImagePaneCss,
  changetextImagePaneCss,
} from "./Hero.css"
import HeroHeading from "./HeroHeading"
import HorizontalDottedLine from "./HorizontalDottedLine"
import ImagePane from "./ImagePane"
import RotatingText from "./RotatingText"
import useInterval from "./useTimer"
import VerticalDottedLine from "./VerticalDottedLine"
import { slides } from "@/components/marketing/home/content"

const SLIDE_DURATION = 6000

type ProgressRingProps = BoxProps & {
  progress: number
  accentColor: string
  size?: number
  strokeWidth?: number
}

const ProgressRing = ({
  progress,
  accentColor,
  size = 38,
  strokeWidth = 1,
  ...props
}: ProgressRingProps) => {
  const center = size / 2
  const radius = center - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  return (
    <Box {...props}>
      <svg
        width="100%"
        height="100%"
        style={{
          opacity: 0.25,
          transform: "rotate(-90deg)",
        }}
      >
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          stroke="rgba(0,0,0,0.1)"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          stroke={"rgba(255,255,255,.5)"}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference * (1 - progress / 100)}
          strokeLinecap="round"
        />
      </svg>
    </Box>
  )
}

const Hero = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const currentSlide = slides[currentSlideIndex] || slides[0]
  const [isPlaying, setIsPlaying] = useState(true)
  const [isHovering, setIsHovering] = useState(false)

  const nextSlide = useCallback(() => {
    setCurrentSlideIndex((prev) => (prev + 1 + slides.length) % slides.length)
  }, [])

  const { elapsedTime, resetElapsedTime } = useInterval({
    callback: nextSlide,
    duration: SLIDE_DURATION,
    isPlaying,
  })

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        ["INPUT", "TEXTAREA"].includes((event.target as HTMLElement)?.tagName)
      ) {
        return
      }

      if (event.key === "ArrowLeft") {
        resetElapsedTime()
        setCurrentSlideIndex(
          (prev) => (prev - 1 + slides.length) % slides.length
        )
      } else if (event.key === "ArrowRight") {
        resetElapsedTime()
        setCurrentSlideIndex(
          (prev) => (prev + 1 + slides.length) % slides.length
        )
      } else if (event.key === " ") {
        event.preventDefault()
        setIsPlaying((prev) => !prev)
      }
    }

    window.addEventListener("keydown", handleKeyDown)
    return () => window.removeEventListener("keydown", handleKeyDown)
  }, [isPlaying, resetElapsedTime])

  const controlsRow = (
    <Row gap="1x">
      {false && (
        <IconButtonV4
          variant="ghost"
          icon={CaretLeft}
          onClick={() => {
            resetElapsedTime()
            setCurrentSlideIndex(
              (currentSlideIndex - 1 + slides.length) % slides.length
            )
          }}
          style={{
            borderRadius: "50%",
            opacity: 0.25,
          }}
        />
      )}
      <Box position="relative">
        <IconButtonV4
          variant="ghost"
          icon={isPlaying || isHovering ? Pause : Play}
          onClick={() => {
            setIsPlaying((prev) => !prev)
          }}
          iconProps={{
            weight: "fill",
          }}
          style={{
            borderRadius: "50%",
            opacity: isHovering ? 1 : 0.25,
            transition: "opacity 200ms",
          }}
        />
        <ProgressRing
          progress={(elapsedTime / SLIDE_DURATION) * 100}
          accentColor={currentSlide.accentColor} // Pass the accentColor of the current slide
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
      {false && (
        <IconButtonV4
          variant="ghost"
          icon={CaretRight}
          onClick={() => {
            resetElapsedTime()
            setCurrentSlideIndex(
              (currentSlideIndex + 1 + slides.length) % slides.length
            )
          }}
          style={{
            borderRadius: "50%",
            opacity: 0.25,
          }}
        />
      )}
    </Row>
  )

  return (
    <Box>
      <Container as={Stack} className={heroContainerCss} gap="1x">
        <Stack
          alignItems="flexStart"
          className={desktopControlsContainerCss}
          style={{
            position: "absolute",
            right: "-4.5%",
            top: "100px",
          }}
        >
          {controlsRow}
        </Stack>
        <Box
          className={heroGridCss}
          onMouseEnter={() => {
            setIsHovering(true)
            setIsPlaying(false)
          }}
          onMouseLeave={() => {
            setIsHovering(false)
            setIsPlaying(true)
          }}
        >
          <Row
            start
            className={responsiveGapCss}
            style={{
              textAlign: "left",
              gridArea: "blockchain",
            }}
          >
            <HeroHeading className={fadeInUpQuickCss} animationIndex={0}>
              Blockchain
            </HeroHeading>
            <ImagePane
              currentSlideIndex={currentSlideIndex}
              containerStyle={{
                flex: 1,
                height: "100%",
                backgroundColor: currentSlide.accentColor,
              }}
              backgroundImageClass={blockchainImagePaneCss}
            />
          </Row>
          <Row
            className={responsiveGapCss}
            style={{
              gridArea: "data",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                textAlign: "left",
              }}
            >
              <HeroHeading className={fadeInUpQuickCss} animationIndex={1}>
                data
              </HeroHeading>
            </Box>
            <Box
              style={{
                height: "100%",
                flex: 1,
              }}
            >
              <ImagePane
                currentSlideIndex={currentSlideIndex}
                containerStyle={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: currentSlide.accentColor,
                }}
                backgroundImageClass={dataImagePaneCss}
              />
            </Box>
          </Row>
          <Box className={hideSectionCss} style={{ gridArea: "section2" }}>
            <ImagePane
              currentSlideIndex={currentSlideIndex}
              containerStyle={{
                width: "100%",
                height: "100%",
                backgroundColor: currentSlide.accentColor,
                transition: "background-color 1000ms",
              }}
              imageStyle={{
                backgroundPosition: "top right",
              }}
            />
          </Box>

          <Box className={hideSectionCss} style={{ gridArea: "section4" }}>
            <ImagePane
              currentSlideIndex={currentSlideIndex}
              containerStyle={{
                width: "100%",
                height: "100%",
                backgroundColor: currentSlide.accentColor,
              }}
              imageStyle={{
                backgroundPosition: "bottom left",
              }}
            />
          </Box>
          <Row
            className={responsiveGapCss}
            style={{
              gridArea: "youcan",
              textAlign: "right",
            }}
          >
            <ImagePane
              currentSlideIndex={currentSlideIndex}
              containerStyle={{
                height: "100%",
                width: "100%",
                backgroundColor: currentSlide.accentColor,
              }}
              backgroundImageClass={youCanImagePaneCss}
            />
            <Box
              style={{
                textAlign: "right",
              }}
            >
              <HeroHeading className={fadeInUpQuickCss} animationIndex={2}>
                you can
              </HeroHeading>
            </Box>
          </Row>
          <Row
            start
            style={{
              gridArea: "changetext",
              textAlign: "right",
              gap: GAP / 2 + "px",
            }}
          >
            <ImagePane
              currentSlideIndex={currentSlideIndex}
              containerStyle={{
                flex: 1,
                height: "100%",
                backgroundColor: currentSlide.accentColor,
              }}
              backgroundImageClass={changetextImagePaneCss}
            />
            <RotatingText currentSlideIndex={currentSlideIndex} />
          </Row>

          <Box
            className={fadeInUpQuickCss}
            style={{
              gridArea: "section3",
            }}
          >
            <FeaturedContent currentSlideIndex={currentSlideIndex} />
          </Box>

          {/* Lines */}
          <HorizontalDottedLine
            animationDelayIndex={0}
            currentSlideIndex={currentSlideIndex}
            positionStyle={{
              top: "-30px",
              gridColumn: "1 / 17",
              gridRow: "blockchain",
              marginLeft: "-500px",
              marginRight: "-500px",
            }}
          />
          <HorizontalDottedLine
            animationDelayIndex={1}
            currentSlideIndex={currentSlideIndex}
            positionStyle={{
              bottom: "10px",
              marginLeft: "-500px",
              marginRight: "-500px",
              gridColumn: "1 / 17",
              gridRow: "6",
            }}
          />
          <HorizontalDottedLine
            currentSlideIndex={currentSlideIndex}
            animationDelayIndex={1}
            positionStyle={{
              gridColumn: "1 / 5",
              gridRow: "3",
              top: "-10px",
              marginLeft: "-500px",
            }}
          />
          <HorizontalDottedLine
            currentSlideIndex={currentSlideIndex}
            animationDelayIndex={2}
            positionStyle={{
              gridColumn: "12 / 17",
              gridRow: "4",
              top: "-10px",
              marginRight: "-500px",
            }}
          />
          <VerticalDottedLine
            currentSlideIndex={currentSlideIndex}
            animationDelayIndex={0}
            positionStyle={{
              gridColumn: "1",
              gridRow: "1 / 4",
              left: "-20px",
            }}
          />
          <VerticalDottedLine
            currentSlideIndex={currentSlideIndex}
            animationDelayIndex={1}
            positionStyle={{
              gridColumn: "16",
              gridRow: "1 / 4",
              right: "-75px",
            }}
          />
        </Box>
        <Row
          className={heroContentSectionCss}
          onMouseEnter={() => {
            setIsHovering(true)
            setIsPlaying(false)
          }}
          onMouseLeave={() => {
            setIsHovering(false)
            setIsPlaying(true)
          }}
        >
          <Text
            color="muted"
            textAlign="left"
            size="16"
            style={{ maxWidth: "700px" }}
          >
            Token Terminal is a full stack onchain data platform focused on
            standardizing financial and alternative data for the most widely
            used blockchains and decentralized applications.
          </Text>
          <LinkButtonV4
            variant="ghost"
            size="large"
            href={currentSlide.ctaHref}
            className={desktopCtaCss}
          >
            {currentSlide.ctaText}
            <Icon as={ArrowRight} />
          </LinkButtonV4>
        </Row>
        <Row width="100%">
          <Box>
            <LinkButtonV4
              variant="ghost"
              size="medium"
              href={currentSlide.ctaHref}
              className={mobileCTACss}
              style={{
                marginLeft: "-20px",
              }}
            >
              {currentSlide.ctaText}
              <Icon as={ArrowRight} />
            </LinkButtonV4>
          </Box>
          <Box className={tabletControlsCss}>{controlsRow}</Box>
        </Row>
      </Container>
    </Box>
  )
}

export default Hero
