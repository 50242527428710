import { useEffect, useRef, useState } from "react"

function useInterval({
  callback,
  duration,
  intervalDuration = 50,
  isPlaying,
}: {
  callback: () => void
  duration: number
  intervalDuration?: number
  isPlaying: boolean
}) {
  const [elapsedTime, setElapsedTime] = useState(0)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (!intervalDuration || !duration || !isPlaying) return

    // Start the interval only when isPlaying is true
    intervalRef.current = setInterval(() => {
      setElapsedTime((prev) => prev + intervalDuration)
    }, intervalDuration)

    // Cleanup interval when component unmounts or isPlaying changes
    return () => clearInterval(intervalRef.current as unknown as number)
  }, [intervalDuration, duration, isPlaying])

  useEffect(() => {
    // When elapsed time reaches the callback duration, call the callback
    if (elapsedTime >= duration) {
      callback()
      setElapsedTime(0) // Reset the elapsed time
    }
  }, [elapsedTime, callback, duration])

  // Manual reset of elapsed time (if needed externally)
  const resetElapsedTime = () => setElapsedTime(0)

  return { elapsedTime, resetElapsedTime }
}

export default useInterval
