import { Box } from "@tokenterminal/ui/Box"
import { fadeInUpQuickCss } from "components/marketing/common/common.css"
import { slides } from "../content"

type Props = {
  currentSlideIndex: number
}

const GAP = 10

const FeaturedContent = ({ currentSlideIndex }: Props) => {
  return (
    <Box
      className={fadeInUpQuickCss}
      style={{
        height: "100%",
        width: "100%",
        transition: "width 0.75s 0.1s cubic-bezier(0.19, 1, 0.22, 1)",
        position: "relative",
        zIndex: 3,
      }}
    >
      {slides.map((slide, index) => (
        <Box
          key={slide.word}
          style={{
            pointerEvents: currentSlideIndex === index ? "auto" : "none",
            height: "100%",
            width: "100%",
            marginBottom: GAP + "px",
            position: "absolute",
            top: 0,
            animationDelay: "0.15s",
            filter: currentSlideIndex === index ? "blur(0px)" : "blur(15px)",
            opacity: currentSlideIndex === index ? 1 : 0,
            transitionDuration: "0.75s",
          }}
        >
          {slide.featuredContent}
        </Box>
      ))}
    </Box>
  )
}

export default FeaturedContent
