import { Box } from "@tokenterminal/ui/Box"
import { blurInCss } from "components/marketing/common/common.css"
import { imagePaneContainerCss, backgroundImageCss } from "./ImagePane.css"
import { slides } from "@/components/marketing/home/content"

type Props = {
  containerStyle?: React.CSSProperties
  currentSlideIndex: number
  imageStyle?: React.CSSProperties
  backgroundImageClass?: string
}

const ImagePane = ({
  containerStyle,
  currentSlideIndex,
  imageStyle,
  backgroundImageClass,
}: Props) => {
  return (
    <Box
      className={[blurInCss, imagePaneContainerCss]}
      style={{
        position: "relative",
        overflow: "hidden",
        isolation: "isolate", // Ensures isolation for blending
        transition: "background-color 1000ms",
        ...containerStyle,
      }}
    >
      {slides.map((slide, index) => (
        <Box
          key={index}
          alt="Image"
          className={[backgroundImageCss, backgroundImageClass]}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            backgroundImage: `url(${slide.backgroundImage})`,
            backgroundPosition: "top 50%",
            opacity: currentSlideIndex === index ? 1 : 0,
            mixBlendMode: "multiply",
            transition: "opacity 1000ms",
            ...imageStyle,
          }}
        />
      ))}
      {/* <Box
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(10deg, rgba(78,78,78,0.19), rgba(45,45,45,0.35)), url(https://grainy-gradients.vercel.app/noise.svg)",
          filter: "contrast(670%) brightness(1200%)",
        }}
      />
      <Box
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          background: slides[currentSlideIndex].accentColor,
          mixBlendMode: "multiply",
        }}
      /> */}
    </Box>
  )
}

export default ImagePane
